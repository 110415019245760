import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Div } from './header-search-style';
import { Popover } from '../popup/popup';
import AppContext from '../../context';
import { Button } from '../buttons/buttons';
import { UserDropDwon } from '../utilities/auth-info/auth-info-style';
import { Key } from 'react-feather';

const HeaderSearch = React.memo(
  () => {
    const { url } = useRouteMatch();

    const context = useContext(AppContext);
    const { user } = context;
    const [funis, setFunis] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const dashboard = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin`}>
                <FeatherIcon icon="globe" /> Dash de Performance do Site
              </Link>
            </li>
            {(user?.permissões.financeiro || user?.permissões.alterar_cadastro) && (
              <li>
                <Link to={`/admin/dash-vendas`}>
                  <FeatherIcon icon="dollar-sign" /> Dash de Vendas
                </Link>
              </li>
            )}
            {user?.permissões.alterar_cadastro && context.empresa.utilizar_temporada && (
              <li>
                <Link to={`/admin/dash-temporada`}>
                  <FeatherIcon icon="map" /> Dash de Temporada
                </Link>
              </li>
            )}
            <li>
              <Link to={`/admin/dash-corretor`}>
                <FeatherIcon icon="git-pull-request" /> Dash de Corretor
              </Link>
            </li>
          </ul>
        </div>
      </UserDropDwon>
    );

    const imoveis = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/lista-de-imoveis`}>
                <FeatherIcon icon="home" /> Lista de Imóveis
              </Link>
            </li>
            {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
              (user?.permissões.imovel_criar || user?.permissões.alterar_cadastro) && (
                <li>
                  <Link to={`/admin/incluir-imovel`}>
                    <FeatherIcon icon="plus" /> Incluir Imóvel
                  </Link>
                </li>
              )}
            {(user?.permissões.empreendimentos || user?.permissões.alterar_cadastro) && (
              <li>
                <Link to={`/admin/empreendimentos`}>
                  <FeatherIcon icon="box" /> Lista de Empreendimentos
                </Link>
              </li>
            )}
            {(user?.permissões.empreendimentos || user?.permissões.alterar_cadastro) &&
              context.empresa.utilizar_construtoras && (
                <li>
                  <Link to={`/admin/construtoras`}>
                    <FeatherIcon icon="truck" /> Lista de Construtoras
                  </Link>
                </li>
              )}
            {(user?.permissões.imovel_editar || user?.permissões.alterar_cadastro) &&
              context.empresa.utilizar_arquitetos && (
                <li>
                  <Link to={`/admin/arquitetos`}>
                    <FeatherIcon icon="users" /> Lista de Arquitetos
                  </Link>
                </li>
              )}
            {((user?.permissões.alterar_cadastro && context.empresa.utilizar_temporada) ||
              context.user?.categoria === 'locacao') && (
              <li>
                <Link to={`/admin/reservas`}>
                  <FeatherIcon icon="map-pin" /> Reservas
                </Link>
              </li>
            )}
            <li>
              <Link to={`/admin/visitas`}>
                <Key size={16} /> Visitas
              </Link>
            </li>
            {(user?.permissões.alterar_cadastro || context.user?.categoria === 'venda') && (
              <li>
                <Link to={`/admin/propostas`}>
                  <FeatherIcon icon="clipboard" /> Propostas
                </Link>
              </li>
            )}
          </ul>
        </div>
      </UserDropDwon>
    );

    const pessoas = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/lista-de-clientes`}>
                <FeatherIcon icon="user-check" /> Listagem de Clientes
              </Link>
            </li>
            {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
              (user?.permissões.lead_criar || user?.permissões.alterar_cadastro) && (
                <li>
                  <Link to={`/admin/incluir-cliente`}>
                    <FeatherIcon icon="user-plus" /> Cadastrar Cliente
                  </Link>
                </li>
              )}
            {context.empresa.utilizar_bolsao_clientes && (
              <li>
                <Link to={`/admin/bolsao-de-clientes`}>
                  <FeatherIcon icon="users" /> Bolsão de clientes
                </Link>
              </li>
            )}
            {((!context.empresa.pipelines_alternativas &&
              user?.permissões.pipeline_leads &&
              context.user?.acesso_venda) ||
              user?.permissões.alterar_cadastro) &&
              !context.empresa.desabilitar_funis_padroes && (
                <li>
                  <Link to={`/admin/pipeline-de-clientes`}>
                    <FeatherIcon icon="activity" /> Funil de Vendas
                  </Link>
                </li>
              )}
          </ul>
        </div>
      </UserDropDwon>
    );

    const marketing = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/campanhas`}>
                <FeatherIcon icon="award" /> Campanhas Meta
              </Link>
            </li>
            <li>
              <Link to={`/admin/portais`}>
                <FeatherIcon icon="share" /> Portais
              </Link>
            </li>
            <li>
              <Link to={`/admin/blogs`}>
                <FeatherIcon icon="book" /> Blogs
              </Link>
            </li>
            {/* <li>
              <Link to={`#`}>
                <FeatherIcon icon="send" /> Landing Pages
              </Link>
            </li> */}
            {/* <li>
              <Link to={`/email-marketing`}>
                <FeatherIcon icon="mail" /> Email Marketing
              </Link>
            </li> */}
          </ul>
        </div>
      </UserDropDwon>
    );

    const configuracoes = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/configuracoes`}>
                <FeatherIcon icon="settings" /> Configurações da Empresa
              </Link>
            </li>
            {user?.permissões.alterar_cadastro && (
              <li>
                <Link to={`/admin/equipes/distribuicao-de-leads`}>
                  <FeatherIcon icon="target" /> Distribuição de Leads
                </Link>
              </li>
            )}
            {user?.permissões.alterar_cadastro && (
              <li>
                <Link to={`/admin/equipes`}>
                  <FeatherIcon icon="users" /> Listagem de Equipes
                </Link>
              </li>
            )}
            {user?.permissões.alterar_cadastro && (
              <li>
                <Link to={`/admin/unidades`}>
                  <FeatherIcon icon="pocket" /> Listagem de Unidades
                </Link>
              </li>
            )}
            <li>
              <Link to={`/admin/equipe`}>
                <FeatherIcon icon="users" /> Listagem de Usuários
              </Link>
            </li>
            {/* <li>
              <Link disabled to={`/admin/suporte`}>
                <FeatherIcon icon="help-circle" /> Chamados de Suporte
              </Link>
            </li>
            <li>
              <Link disabled to={`/admin/novidades`}>
                <FeatherIcon icon="git-commit" /> Novidades
              </Link>
            </li>
            <li>
              <Link disabled to={`#`}>
                <FeatherIcon icon="skip-forward" /> Tour guiado
              </Link>
            </li>
            <li>
              <Link disabled to={`/admin/tutoriais`}>
                <FeatherIcon icon="youtube" /> Tutoriais
              </Link>
            </li> */}
          </ul>
        </div>
      </UserDropDwon>
    );

    const utilidades = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/tarefas`}>
                <FeatherIcon icon="layout" /> Tarefas
              </Link>
            </li>
            <li>
              <Link to={`/admin/central_anexos`}>
                <FeatherIcon icon="folder" /> Anexos
              </Link>
            </li>
            <li>
              <Link to={`/admin/agenda`}>
                <FeatherIcon icon="calendar" /> Agenda
              </Link>
            </li>
            <li>
              <Link to={`/admin/relatorios`}>
                <FeatherIcon icon="pie-chart" /> Relatórios
              </Link>
            </li>
          </ul>
        </div>
      </UserDropDwon>
    );

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
      setLoading(true);
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        filtros: JSON.stringify([
          {
            field: 'ativo',
            operator: 'equal',
            value: true,
          },
        ]),
      });
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/funis-personalizados?${params.toString()}`);
        const data = await response.json();
        setFunis(data);
      } catch (error) {
        console.error('Erro ao buscar funis personalizados:', error);
      } finally {
        setLoading(false);
      }
    };

    const pipelines = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            {context.user?.permissões.alterar_cadastro && (
              <li>
                <Link to={`/admin/configuracoes/personalizar-funis`}>
                  <FeatherIcon icon="filter" /> Personalizar pipelines
                </Link>
              </li>
            )}
            {(funis || []).map((f: any) => (
              <li key={f.id}>
                <Link to={`/admin/pipeline/${f.id}`}>
                  <FeatherIcon icon="activity" /> {f.nome}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </UserDropDwon>
    );

    return (
      <>
        <Div className="certain-category-search-wrapper" style={{ width: '100%' }}>
          <Row className="ant-row-middle">
            <Col xl={12} lg={12} xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={dashboard} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Dashboard
                    </Button>
                  </Popover>
                </Link>
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={imoveis} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Imóveis
                    </Button>
                  </Popover>
                </Link>
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={pessoas} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Atendimentos
                    </Button>
                  </Popover>
                </Link>
                {(context.user?.permissões.alterar_cadastro || (funis || []).length > 0) && (
                  <Link style={{ marginRight: '10px' }} to={url}>
                    <Popover placement={'bottomLeft'} content={pipelines} action="hover">
                      <Button
                        type={'white'}
                        outlined
                        icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                        style={{ border: 'none', flexDirection: 'row-reverse' }}
                      >
                        Pipelines
                      </Button>
                    </Popover>
                  </Link>
                )}
                {(user?.permissões.marketing || user?.permissões.alterar_cadastro) && (
                  <Link style={{ marginRight: '10px' }} to={url}>
                    <Popover placement={'bottomLeft'} content={marketing} action="hover">
                      <Button
                        type={'white'}
                        outlined
                        icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                        style={{ border: 'none', flexDirection: 'row-reverse' }}
                      >
                        Marketing
                      </Button>
                    </Popover>
                  </Link>
                )}
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={configuracoes} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Configurações
                    </Button>
                  </Popover>
                </Link>
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={utilidades} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Ferramentas
                    </Button>
                  </Popover>
                </Link>
              </div>
            </Col>
          </Row>
        </Div>
      </>
    );
  },
  () => true,
);

export default HeaderSearch;
