import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { Row, Col, Skeleton, message, Empty, Spin } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { ActivityContents } from './style';
import AppContext from '../../../../context';
import { Route, Switch } from 'react-router';
import { Corretor, Imóvel } from 'smart-imob-types';
import { Button } from '../../../../components/buttons/buttons';
import FeatherIcon from 'feather-icons-react';
import ModalDoImovel from '../../imovel/ModalDoImovel';
import { UserModal } from '../../../pages/usuarios';
import RegistrarPropostaModal from '../../../lista-de-imoveis/overview/RegistrarPropostaModal';

const List = lazy(() => import('../../../propostas/list'));

const PropostasCliente = ({ db_id, match }: { db_id: string; match: any }) => {
  const { path } = match;

  const context = useContext(AppContext);
  const [propostas, setPropostas] = useState([]);
  const [loading, setLoading] = useState(true);

  const [ModalImovel, setModalImovel] = useState(false);
  const [imovelModal, setImovelModal] = useState<Imóvel | null>(null);
  const [imovelModalAba, setImovelModalAba] = useState('');

  const [viewUser, setViewUser] = useState<Corretor | null>(null);
  const [registrarProposta, setRegistrarProposta] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const filtros: any = [{ field: 'proposta.cliente_id', value: db_id, operator: 'equal' }];

      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        order: JSON.stringify([{ field: 'proposta.created_at', order: 'DESC' }]),
        filtros: JSON.stringify(filtros),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/propostas?${params.toString()}`);
      const data = await response.json();
      setPropostas(data);
    } catch (error) {
      message.error('Um erro ocorreu ao buscar as visitas');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [db_id]);

  const openModalVisualizarImovel = (imovel: Imóvel, aba: string) => {
    setImovelModal(imovel);
    setImovelModalAba(aba);
    setModalImovel(true);
  };

  const openModalVisualizarCorretor = (corretor: Corretor) => {
    setViewUser(corretor);
  };

  const RouteVisitas = route_props => (
    <List
      refetch={fetchData}
      {...route_props}
      propostas={propostas}
      db_id={db_id}
      loading={loading}
      setVisitas={setPropostas}
      total={propostas.length}
      pageSize={propostas.length}
      current={1}
      openModalVisualizarImovel={openModalVisualizarImovel}
      openModalVisualizarCorretor={openModalVisualizarCorretor}
    />
  );

  const realizarProposta = proposta => {
    fetchData();
  };

  return (
    <ActivityContents>
      {imovelModal && (
        <ModalDoImovel
          visible={ModalImovel}
          onCancel={() => setModalImovel(false)}
          imovel={imovelModal}
          imovelModalAba={imovelModalAba}
        />
      )}
      <UserModal usuario={viewUser} visible={!!viewUser} onCancel={() => setViewUser(null)} />{' '}
      <RegistrarPropostaModal
        cliente_id={db_id}
        visible={registrarProposta}
        onCancel={() => setRegistrarProposta(false)}
        onFinish={realizarProposta}
      />
      <Row gutter={25}>
        <Col md={24}>
          <Cards
            title="Propostas do Cliente"
            isbutton={
              <Button size="small" key="he-3" type="primary" onClick={() => setRegistrarProposta(true)}>
                <FeatherIcon icon="plus" size={14} />
                Cadastrar Proposta
              </Button>
            }
          >
            {loading ? (
              <div style={{ padding: '0px 25px' }}>
                <Skeleton active /> <Skeleton active /> <Skeleton active />
              </div>
            ) : (
              <ul className="activity-list">
                {propostas.length > 0 ? (
                  <Switch>
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >
                      <Route path={path} component={RouteVisitas} exact />
                    </Suspense>
                  </Switch>
                ) : (
                  <Empty
                    image={require('../../../../static/img/empty.svg')}
                    description="Este cliente não possui propostas registradas!"
                  />
                )}
              </ul>
            )}
          </Cards>
        </Col>
      </Row>
    </ActivityContents>
  );
};

export default PropostasCliente;
