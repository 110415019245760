import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal } from '../../../components/modals/antd-modals';
import Imovel from "./Index";
import { useHistory } from "react-router-dom";

 

const ModalDoImovel = (props) => {    
    const { url } = useRouteMatch();
    let history = useHistory();

    const onCancel = props => {            
      history.push(`${url}`);      
      props.onCancel()
    }

    return <Modal
        type={'primary'}
        width={'85%'}
        title="Página do Imóvel"
        visible={props.visible}
        footer={null}
        onCancel={() => onCancel(props)}
    >
      <Imovel imovel_dbid={props.imovel.db_id} modalImovel={true} imovelModalAba={props.imovelModalAba}/>
    </Modal>
};

export default ModalDoImovel;