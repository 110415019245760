import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Radio,
  Checkbox,
  Spin,
  message,
  Select,
  DatePicker,
  Tooltip,
  Modal,
  Divider,
} from 'antd';
const { Option } = Select;
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import AppContext from '../../context';
import { SidebarSingle, TopToolBox } from '../lista-de-imoveis/Style';
import { Cliente, Construtora, Corretor } from 'smart-imob-types';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment, { Moment } from 'moment';
import { DateFromUnsafe } from '../../utility/util_functions';
import ModalDoCliente from './ModalDoCliente';
import FeatherIcon from 'feather-icons-react';
import { TabelaDeClientes } from './components/TabelaDeClientes';
import { ClearOutlined } from '@ant-design/icons';
import { ModalAddClientes } from './components/ModalDeAdicionar';
import { ModalEditClientes } from './components/ModalDeEditar';
import { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { StyledSelect } from '../lista-de-imoveis/styled';
import { OptGroup } from 'rc-select';
import { UserModal } from '../pages/usuarios';
import Axios from 'axios';

const minFields = {
  DDD: undefined,
  CPF: undefined,
  telefone: undefined,
  nome: undefined,
  email: undefined,
  proprietario: false,
  corretor_responsavel: undefined,
};

function downloadObjectAsJson(exportObj: any[], exportName: string) {
  var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('target', '_blank');
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

type BuscaAvancadaInputs = {
  nome: string;
  email: string;
  buscar_excluidos: boolean;
  buscar_proprietarios: boolean;
  telefone: string;
  data_cadastro_min: undefined | Moment | undefined;
  data_cadastro_max: undefined | Moment | undefined;
  bant: undefined | string;
  etapa: number | undefined;
  transacao: undefined | string;
  buscar_ganhos: boolean;
  buscar_perdidos: boolean;
  imovel_origem: undefined | string;
  corretor_responsavel: undefined | string;
  interesse: undefined | string;
  etiquetas: string[];
  formularios: string[];
  construtoras: string[];
  origem_site: boolean;
  portais: boolean;
  formulario_id: boolean;
};

interface UserListDataTableProps {
  match: any;
  startAdding: any;
  title: string;
  userId: string;
  bolsao: boolean;
}

const ListaDeClientes = (props: UserListDataTableProps) => {
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [clientes, setClientes] = useState<any[]>([]);
  const context = useContext(AppContext);
  const [idsSelecionados, setIdsSelecionados] = useState<string[]>([]);
  const [formularios, setFormularios] = useState<any[]>([]);
  const [TableReset, setTableReset] = useState(false);
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [clienteModal, setClienteModal] = useState<Cliente | null>(null);
  const [primeiraBusca, setPrimeiraBusca] = useState(true);
  const [buscar, setBuscar] = useState(false);
  const [clientesTotal, setClientesTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ordenacao, setOrdenacao] = useState('recentes');
  const [ImovelObj, setImovelObj] = useState<null | any>(null);
  const [imovel_origem_cod, setimovel_origem_cod] = useState('');
  const [opcoesConstrutoras, setOpcoesConstrutoras] = useState<Construtora[]>([]);
  const [etapas, setEtapas] = useState<any>([]);
  const [opcoesImoveis, setOpcoesImoveis] = useState<{ value: string; label: string }[]>([]);
  const [loadingImoveis, setLoadingImoveis] = useState(false);
  const [imovelFilter, setImovelFilter] = useState('');
  const [viewUser, setViewUser] = useState<Corretor | null>(null);

  const [columnOrder, setColumnOrder] = useState<{ [key: string]: 'ascend' | 'descend' | undefined }>({});
  useEffect(() => {
    const fetchData = async () => {
      if (imovelFilter.trim() === '') {
        setOpcoesImoveis([]);
        return;
      }

      setLoadingImoveis(true);
      try {
        let params = new URLSearchParams({
          empresa_id: context.empresa.db_id,
          order: JSON.stringify([{ field: 'imovel.codigo', order: 'ASC' }]),
          filtros: JSON.stringify([{ field: 'imovel.codigo', value: `%${imovelFilter}%`, operator: 'like' }]),
        });
        const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/paginado?${params.toString()}`);
        const data = await response.json();
        setOpcoesImoveis(data.nodes.map(node => ({ value: node.db_id, label: node.codigo })));
      } catch (error) {
        console.error('Falha ao buscar imóveis:', error);
      }
      setLoadingImoveis(false);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [imovelFilter, context.empresa.db_id]);

  const [state, setState] = useState<any>({
    selectedRowKeys: 0,
    selectedRows: 0,
    visible: !!props.startAdding,
    editVisible: false,
    update: {},
  });
  const [dataTipo, setDataTipo] = useState<'created_at' | 'edited_at' | 'follow_up'>('created_at');

  const { update } = state;

  const last_filtro_str = sessionStorage.getItem('last_filtro_clientes');
  const last_filtro = last_filtro_str ? JSON.parse(last_filtro_str) : {};

  if (last_filtro) {
    if (last_filtro.data_cadastro_min) {
      last_filtro.data_cadastro_min = moment(DateFromUnsafe(last_filtro.data_cadastro_min));
    }
    if (last_filtro.data_cadastro_max) {
      last_filtro.data_cadastro_max = moment(DateFromUnsafe(last_filtro.data_cadastro_max));
    }
  }

  const FiltrosIniciais = {
    nome: last_filtro.nome || '',
    buscar_excluidos: last_filtro.buscar_excluidos || false,
    buscar_proprietarios: last_filtro.buscar_proprietarios || false,
    email: last_filtro.email || '',
    telefone: last_filtro.telefone || '',
    data_cadastro_min: last_filtro.data_cadastro_min || null,
    data_cadastro_max: last_filtro.data_cadastro_max || null,
    imovel_origem: last_filtro.imovel_origem || [],
    etiquetas: [],
    bant: last_filtro.bant || null,
    formularios: last_filtro.formularios || [],
    interesse: last_filtro.interesse || null,
    etapa: last_filtro.etapa || null,
    transacao: last_filtro.transacao || null,
    buscar_ganhos: last_filtro.buscar_ganhos || '',
    buscar_perdidos: last_filtro.buscar_perdidos || '',
    corretor_responsavel: last_filtro.corretor_responsavel || null,
    construtoras: last_filtro.construtoras || [],
    origem_site: last_filtro.origem_site || null,
    portais: last_filtro.lead_grupo_zap || null,
    formulario_id: last_filtro.formulario_id || null,
    ...last_filtro,
  };

  const limparFiltros = () => {
    setFiltros(FiltrosIniciais);
    setBuscar(true);
    message.success('Filtros limpos!');
  };
  const [filtros, setFiltros] = useState<BuscaAvancadaInputs>(FiltrosIniciais);
  const [BuscaExtra, setBuscaExtra] = useState(filtros.imovel_origem ? true : false);

  const handleLimpar = () => {
    limparFiltros();
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const url = `${process.env.REACT_APP_API_URI}/construtoras?${params.toString()}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const construtoras = await response.json();
      setOpcoesConstrutoras(construtoras);
    };
    fetchData();
  }, []);

  const handleChange = (campo, valor) => {
    setFiltros({
      ...filtros,
      [campo]: valor,
    });
  };

  const corretoresRelate: Record<string, string> = context.users_empresa.reduce((prev: any, curr: any) => {
    prev[curr.db_id] = curr.nome || curr.email || curr.db_id;
    return prev;
  }, {} as Record<string, string>);

  const [form] = Form.useForm();

  const operadoresEspecificos = {
    nome: 'like',
    email: 'like',
    telefone: 'like',
    data_cadastro_min: 'moreThanOrEqual',
    data_cadastro_max: 'lessThanOrEqual',
    imovel_origem: 'in',
    corretor_responsavel: 'equal',
    buscar_excluidos: 'equal',
    buscar_proprietarios: 'equal',
    etapa: 'equal',
    transacao: 'equal',
    interesse: 'equal',
    bant: 'equal',
    etiquetas: 'in',
    formularios: 'in',
    construtoras: 'in',
    formulario_id: 'isNotNull',
  };

  const camposEspecificos: any = {
    nome: 'cliente.nome',
    email: 'cliente.email',
    telefone: 'cliente.telefone',
    imovel_origem: 'cliente.imovel_origem',
    corretor_responsavel: 'cliente.corretor_responsavel',
    buscar_excluidos: 'cliente.excluido',
    buscar_proprietarios: 'cliente.proprietario',
    etapa: 'cliente.etapa_funil_id',
    transacao: 'cliente.transacao',
    interesse: 'cliente.interesse',
    bant: 'cliente.bant',
    etiquetas: 'cliente.etiquetas',
    formularios: 'formulario.id',
    construtoras: 'imovel.construtora_id',
    etapa_funil: 'etapa_funil.title',
    created_at: 'cliente.created_at',
  };

  if (dataTipo === 'follow_up') {
    camposEspecificos.data_cadastro_min = 'timeline.data';
    camposEspecificos.data_cadastro_max = 'timeline.data';
  } else {
    camposEspecificos.data_cadastro_min = `cliente.${dataTipo}`;
    camposEspecificos.data_cadastro_max = `cliente.${dataTipo}`;
  }

  const processarFiltros = (filtros: any) => {
    const filtrosProcessados: any = [];
    for (const chave in filtros) {
      const valor = filtros[chave];
      if (Array.isArray(valor) && valor.length === 0) continue;
      if (
        valor === null ||
        valor === '' ||
        (!moment.isMoment(valor) &&
          typeof valor !== 'string' &&
          typeof valor !== 'number' &&
          typeof valor !== 'boolean' &&
          !Array.isArray(valor))
      )
        continue;

      const operator = operadoresEspecificos[chave] || 'equal';
      const valorProcessado = operator === 'like' ? `%${valor}%` : valor;
      const campo = camposEspecificos[chave] || `cliente.${chave}`;

      if (chave === 'data_cadastro_min' || chave === 'data_cadastro_max') {
        if (valor && moment.isMoment(valor)) {
          let valorFormatado;

          if (chave === 'data_cadastro_min') {
            valorFormatado = valor.startOf('day').toISOString();
          } else if (chave === 'data_cadastro_max') {
            valorFormatado = valor.endOf('day').toISOString();
          }

          if (valorFormatado) {
            filtrosProcessados.push({
              field: campo,
              value: valorFormatado,
              operator: operator,
            });
          }
        }
      } else {
        if (valorProcessado === undefined || valorProcessado === null) continue;

        filtrosProcessados.push({
          field: campo,
          value: valorProcessado,
          operator: operator,
        });
      }
    }
    return filtrosProcessados;
  };

  const fetchClientes = async () => {
    if (!buscar) return;
    try {
      setLoadingClientes(true);
      const maisFiltros: any = [];

      if (ordenacao === 'meus' && !props.userId) {
        maisFiltros.push({ field: 'cliente.corretor_responsavel', value: context.user?.db_id, operator: 'equal' });
      }

      if (props.bolsao) {
        maisFiltros.push({ field: 'cliente.corretor_responsavel', value: '1', operator: 'isNull' });
        maisFiltros.push({ field: 'cliente.bolsao', value: 1, operator: 'equal' });
        maisFiltros.push({ field: 'cliente.equipe_id', value: context?.user?.equipe_id, operator: 'equal' });
        maisFiltros.push({ field: 'cliente.regra_distribuicao', value: context?.user?.db_id, operator: 'equal' });
      }
      
      if (!(context.user?.acesso_venda && context.user?.acesso_locacao)) {
        if (context.user?.acesso_venda) {
          maisFiltros.push({ field: 'cliente.interesse_locacao', value: 1, operator: 'ne' });
          maisFiltros.push({ field: 'cliente.interesse_locacao_temporada', value: 1, operator: 'ne' });
        }

        if (context.user?.acesso_locacao) {
          maisFiltros.push({ field: 'cliente.interesse_venda', value: 1, operator: 'ne' });
        }
      }

      if (!context.user?.permissões.alterar_cadastro && !context.user?.permissões.lead_ver_todos) {
        maisFiltros.push({ field: 'cliente.corretor_responsavel_id', value: context.user?.db_id, operator: 'equal' });
      }
      const filtrosProcessados = processarFiltros(filtros);
      const isFiltrosVazio = Object.keys(filtrosProcessados).length === 0;
      const filtrosParaRequisicao = isFiltrosVazio ? [...maisFiltros] : [...filtrosProcessados, ...maisFiltros];

      const sorters: any = [];
      const ordemMapa = {
        recentes: { field: 'cliente.created_at', order: 'DESC' },
        edicao: { field: 'cliente.edited_at', order: 'DESC' },
        follow_up: { field: 'timeline.data', order: 'DESC' },
        meus: { field: 'cliente.corretor_responsavel', order: 'DESC' },
      };
      const ordem = ordemMapa[ordenacao];

      const sortersOBJ = Object.entries(columnOrder);
      const sortersProcessado = sortersOBJ
        .map(([key, value]) => ({
          field: camposEspecificos[key] || key,
          order: value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : undefined,
        }))
        .filter(e => e.order);

      if (sortersProcessado && sortersProcessado.length > 0) {
        sorters.push(...sortersProcessado);
      } else {
        ordem && sorters.push(ordem);
      }

      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        limit: pageSize.toString(),
        startAt: ((current - 1) * pageSize).toString(),
        order: JSON.stringify(sorters),
        filtros: JSON.stringify(filtrosParaRequisicao),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/paginado?${params.toString()}`);

      const data = await response.json();
      setClientes(data.nodes);
      setClientesTotal(data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setPrimeiraBusca(false);
      setPrimeiraBusca(false);
      setLoadingClientes(false);
      setBuscar(false);
    }
  };

  const fetchFormularios = async () => {
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        order: JSON.stringify([{ field: 'form.name', order: 'ASC' }]),
        filtros: JSON.stringify([
          {
            field: 'form.status',
            operator: 'eq',
            value: 'ACTIVE',
          },
        ]),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/meta/formularios?${params.toString()}`);

      const data = await response.json();
      setFormularios(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFormularios();
  }, []);

  const fetchEtapas = async () => {
    try {
      const filtros: any = [
        // {
        //   field: 'funil_personalizado_id',
        //   value: null,
        //   operator: 'isNull',
        // },
      ];
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        filtros: JSON.stringify(filtros),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-etapas?${params.toString()}`);

      const data = await response.json();
      setEtapas(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEtapas();
  }, []);

  useEffect(() => {
    fetchClientes();
  }, [pageSize, current, ordenacao, buscar, columnOrder]);

  const onHandleDelete = async (id: string) => {
    try {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        edited_by_id: context.user?.db_id || '',
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${id}?${params.toString()}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Falha ao excluir o cliente');
      }

      setClientes(
        clientes.map(cliente =>
          cliente.db_id === id ? { ...cliente, excluido: true, ativo: 0, edited_at: new Date() } : cliente,
        ),
      );

      setTableReset(true);
    } catch (error) {}
  };

  const onHandleFish = async (id: string) => {
    try {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/capturar-bolsao/${id}/${context.user?.db_id}?${params.toString()}`, {
        method: 'PUT',
      });

      if (!response.ok) {
        const error = await response.json();
        message.error(error.message || 'Falha ao capturar o cliente!');
        throw new Error('Falha ao excluir o cliente');
      }

      setClientes(clientes.filter(cliente => cliente.db_id !== id));
      message.success('O cliente foi capturado e agora entra na sua lista de clientes!');
    } catch (error) {}
  };

  const onHandleRevive = async (id: string) => {
    try {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        edited_by_id: context.user?.db_id || '',
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/restore/${id}?${params.toString()}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Falha ao restaurar o cliente');
      }

      setClientes(
        clientes.map(cliente =>
          cliente.db_id === id ? { ...cliente, excluido: false, ativo: 1, edited_at: new Date() } : cliente,
        ),
      );

      setTableReset(true);
    } catch (error) {
      console.error('Erro ao restaurar cliente:', error);
    }
  };

  const showModal = () => {
    setState({
      ...state,
      editVisible: false,
      visible: true,
    });

    setimovel_origem_cod('');
    setImovelObj(null);

    const fields = Object.entries(minFields).map(([key, value]) => ({ name: key, value }));
    form.setFields(fields);
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
      editVisible: false,
      update: {},
    });
    setImovelObj(null);
    setimovel_origem_cod('');
  };

  const [razoes, setRazoes] = useState<any[]>([]);

  const fetchRazoes = async () => {
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-razoes?${params.toString()}`);
      const data = await response.json();
      setRazoes(data);
    } catch (error) {
      console.error('Erro ao buscar razões:', error);
    }
  };

  useEffect(() => {
    fetchRazoes();
  }, []);

  const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
  const [currentClientId, setCurrentClientId] = useState<string | null>(null);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [newReasonInput, setNewReasonInput] = useState<string>('');
  const [adding, setAdding] = useState(false);

  const openReasonModal = cliente => {
    setCurrentClientId(cliente.db_id);
    setSelectedReason(cliente.razao_id || null);
    setIsReasonModalVisible(true);
  };

  const closeReasonModal = () => {
    setIsReasonModalVisible(false);
    setCurrentClientId(null);
    setSelectedReason(null);
  };

  const handleStatusChange = (cliente: Cliente, status: 'ganho' | 'perdido') => {
    if (status === 'perdido') {
      openReasonModal(cliente);
    } else if (status === 'ganho') {
      updateClientStatus(cliente.db_id, status, undefined);
    }
  };

  const updateClientStatus = async (clientId: string, status: 'ganho' | 'perdido', razao_id?: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${clientId}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          status,
          razao_id,
          corretor_responsavel_id: null,
          corretor_responsavel: null,
          edited_by_id: context.user?.db_id || undefined,
        }),
      });
      if (!response.ok) {
        throw new Error('Falha ao atualizar o status do cliente');
      }
      message.success('Status atualizado com sucesso');
      setClientes(prevClientes =>
        prevClientes.map(cliente =>
          cliente.db_id === clientId
            ? {
                ...cliente,
                status,
                razao_id,
                edited_at: new Date(),
                corretor_responsavel_id: null,
                corretor_responsavel: null,
              }
            : cliente,
        ),
      );
    } catch (error) {
      console.error(error);
      message.error('Erro ao atualizar status');
    }
  };

  const addReason = async () => {
    if (!newReasonInput.trim()) {
      message.error('Por favor, insira um motivo válido.');
      return;
    }

    setAdding(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-razoes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nome: newReasonInput.trim(), empresa_id: context.empresa.db_id }),
      });

      if (!response.ok) {
        throw new Error('Erro ao adicionar o motivo');
      }

      const newReason = await response.json();
      setRazoes(prev => [...prev, newReason]);
      message.success('Motivo adicionado com sucesso!');
      setNewReasonInput('');
    } catch (error) {
      console.error('Erro ao adicionar motivo:', error);
      message.error('Erro ao adicionar o motivo. Tente novamente.');
    } finally {
      setAdding(false);
    }
  };

  const confirmReason = () => {
    if (currentClientId && selectedReason) {
      updateClientStatus(currentClientId, 'perdido', selectedReason);
      closeReasonModal();
      message.success('Cliente marcado como perdido.');
    } else {
      message.error('Por favor, selecione um motivo.');
    }
  };

  const handleDeleteReason = async (id: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-razoes/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Erro ao excluir o motivo');
      }

      setRazoes(prevRazoes => prevRazoes.filter(razao => razao.id !== id));
      message.success('Motivo excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir motivo:', error);
      message.error('Erro ao excluir o motivo. Tente novamente.');
    }
  };

  const openModalVisualizarCorretor = (corretor: Corretor) => {
    setViewUser(corretor);
  };

  const rowSelection = {
    onChange: (idsSelecionados: any) => setIdsSelecionados(idsSelecionados),
  };

  const showEditModal = (data: any, focusAg = false) => {
    setState({
      ...state,
      visible: false,
      editVisible: true,
      update: data,
    });

    setimovel_origem_cod('');
    setImovelObj(
      data.imovel_origem
        ? {
            value: data.imovel_origem,
          }
        : null,
    );

    //https://ant.design/components/form/#components-form-demo-dynamic-form-item
    //https://ant.design/components/form/#FieldData
    const fields = Object.entries({ ...minFields, ...data }).map(([key, value]) => ({ name: key, value }));
    form.setFields(fields);
    if (focusAg) {
      //não to nem ai não to nem ai
      setTimeout(() => {
        document.getElementById('f_ag_edit')?.focus();
      }, 500);
    }
  };

  const adicionarModalCliente = cliente => {
    setClienteModal(cliente);
    setShowModalCliente(true);
  };

  const pesquisar = () => {
    window.scrollTo(0, 0);
    return setBuscar(true);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      pesquisar();
    }
  };

  const onFinishEdit = (updatedCliente: Cliente) => {
    setClientes(clientes.map(cliente => (cliente.db_id === state.update?.db_id ? { ...updatedCliente } : cliente)));
    setTableReset(true);
    setBuscar(true);
  };

  document.addEventListener('keydown', handleKeyDown);

  async function generateExcel() {
    setLoadingExcel(true);
    try {
      const maisFiltros: any = [];

      if (ordenacao === 'meus') {
        maisFiltros.push({ field: 'cliente.corretor_responsavel', value: context.user?.db_id, operator: 'equal' });
      }

      if (!(context.user?.acesso_venda && context.user?.acesso_locacao)) {
        if (context.user?.acesso_venda) {
          maisFiltros.push({ field: 'cliente.interesse_venda', value: 1, operator: 'equal' });
        }

        if (context.user?.acesso_locacao) {
          maisFiltros.push({ field: 'cliente.interesse_venda', value: 1, operator: 'ne' });
        }
      }

      if (!context.user?.permissões.alterar_cadastro && !context.user?.permissões.lead_ver_todos) {
        maisFiltros.push({ field: 'cliente.corretor_responsavel_id', value: context.user?.db_id, operator: 'equal' });
      }
      const filtrosProcessados = processarFiltros(filtros);
      const isFiltrosVazio = Object.keys(filtrosProcessados).length === 0;
      const filtrosParaRequisicao = isFiltrosVazio ? [...maisFiltros] : [...filtrosProcessados, ...maisFiltros];

      const sorters: any = [];
      const ordemMapa = {
        recentes: { field: 'cliente.created_at', order: 'DESC' },
        edicao: { field: 'cliente.edited_at', order: 'DESC' },
        follow_up: { field: 'timeline.data', order: 'DESC' },
        meus: { field: 'cliente.corretor_responsavel', order: 'DESC' },
      };
      const ordem = ordemMapa[ordenacao];

      const sortersOBJ = Object.entries(columnOrder);
      const sortersProcessado = sortersOBJ
        .map(([key, value]) => ({
          field: camposEspecificos[key] || key,
          order: value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : undefined,
        }))
        .filter(e => e.order);

      if (sortersProcessado && sortersProcessado.length > 0) {
        sorters.push(...sortersProcessado);
      } else {
        ordem && sorters.push(ordem);
      }

      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        order: JSON.stringify(sorters),
        filtros: JSON.stringify(filtrosParaRequisicao),
      });

      const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/clientes/excel/sql?${params.toString()}`, {
        responseType: 'arraybuffer',
      });

      if (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `relatorio-de-clientes.xlsx`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExcel(false);
    }
  }

  return (
    <>
      <PageHeader
        ghost
        title={props.title}
        buttons={[
          <div key="2" className="page-header-actions">
            <Button size="small" key="4" onClick={pesquisar} type="primary">
              <FeatherIcon icon="refresh-cw" size={14} />
              Atualizar lista
            </Button>
            <div className="table-toolbox-actions">
              <Button
                onClick={() => generateExcel()}
                disabled={clientesTotal === 0}
                size="small"
                type="primary"
                transparented
                loading={loadingExcel}
              >
                Gerar Excel
              </Button>
              <Button
                onClick={() =>
                  downloadObjectAsJson(
                    clientes.filter(cliente => idsSelecionados.includes(cliente.db_id)),
                    'clientes',
                  )
                }
                disabled={idsSelecionados.length === 0}
                size="small"
                type="primary"
                transparented
              >
                Gerar JSON
              </Button>
            </div>
            {(context.user?.permissões.lead_criar || context.user?.permissões.alterar_cadastro) && (
              <Button size="small" key="5" onClick={showModal} type="primary">
                <FeatherIcon icon="plus" size={14} />
                Cadastrar cliente
              </Button>
            )}
          </div>,
        ]}
      />
      {state.editVisible && (
        <ModalEditClientes
          visible={state.editVisible}
          onCancel={onCancel}
          cliente={update}
          imovel_origem_cod={imovel_origem_cod}
          imovel_padrao={ImovelObj}
          onFinishEdit={onFinishEdit}
        />
      )}
      <UserModal usuario={viewUser} visible={!!viewUser} onCancel={() => setViewUser(null)} />{' '}
      <ModalAddClientes visible={state.visible} onCancel={onCancel} />
      <ModalDoCliente
        match={props.match}
        visible={showModalCliente}
        onCancel={() => setShowModalCliente(false)}
        cliente={clienteModal}
      />
      <Modal
        title={<span style={{ fontWeight: 'bold', fontSize: '18px' }}>Selecionar Motivo</span>}
        visible={isReasonModalVisible}
        onOk={confirmReason}
        onCancel={closeReasonModal}
        okText="Confirmar"
        cancelText="Cancelar"
        okButtonProps={{
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          style: { backgroundColor: '#52c41a', borderColor: '#52c41a' },
        }}
        cancelButtonProps={{
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        }}
      >
        <Select
          placeholder="Selecione um motivo"
          style={{ width: '100%' }}
          value={selectedReason || undefined}
          onChange={value => setSelectedReason(value)}
          dropdownRender={menu => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Input
                style={{ width: '100%', margin: 8 }}
                placeholder="Digite um novo motivo"
                value={newReasonInput}
                onChange={e => setNewReasonInput(e.target.value)}
                addonAfter={
                  <Tooltip title="Adicionar motivo">
                    <Button
                      icon={<PlusOutlined />}
                      onClick={addReason}
                      type="primary"
                      loading={adding}
                      disabled={adding}
                    />
                  </Tooltip>
                }
              />
            </>
          )}
        >
          {razoes.map(razao => (
            <Select.Option key={razao.id} value={razao.id}>
              {razao.nome}
            </Select.Option>
          ))}
        </Select>
      </Modal>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <TopToolBox>
                <Row gutter={[8, 8]} justify="space-between" align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={6} style={{ textAlign: 'center', marginBottom: '16px' }}>
                    <div className="table-search-box" style={{ display: 'flex', justifyContent: 'center' }}>
                      <AutoComplete
                        dataSource={[]}
                        placeholder="Busca"
                        width="100%"
                        patterns
                        onClick={pesquisar}
                        defaultValue={filtros.nome}
                        onSearch={valor => handleChange('nome', valor)}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <div
                      className="table-toolbox-menu"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <span className="toolbox-menu-title" style={{ whiteSpace: 'nowrap' }}>
                        Ordenar por:
                      </span>
                      <Radio.Group
                        onChange={e => setOrdenacao(e.target.value)}
                        value={ordenacao}
                        style={{ display: 'flex', flexWrap: 'nowrap' }}
                      >
                        <Radio.Button value="recentes" style={{ padding: '4px 8px', fontSize: '10px' }}>
                          Recentes
                        </Radio.Button>
                        <Radio.Button value="meus" style={{ padding: '4px 8px', fontSize: '10px' }}>
                          Meus leads
                        </Radio.Button>
                        <Radio.Button value="edicao" style={{ padding: '4px 8px', fontSize: '10px' }}>
                          Últ. Edição
                        </Radio.Button>
                        <Radio.Button value="follow_up" style={{ padding: '4px 8px', fontSize: '10px' }}>
                          Últ. Follow Up
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <div
                      className="table-toolbox-menu"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <span className="toolbox-menu-title" style={{ whiteSpace: 'nowrap' }}>
                        Filtrar por data de:
                      </span>
                      <Radio.Group
                        onChange={e => setDataTipo(e.target.value)}
                        value={dataTipo}
                        style={{ display: 'flex', flexWrap: 'nowrap' }}
                      >
                        <Radio.Button value="created_at" style={{ padding: '4px 8px', fontSize: '14px' }}>
                          Cadastro
                        </Radio.Button>
                        <Radio.Button value="edited_at" style={{ padding: '4px 8px', fontSize: '14px' }}>
                          Edição
                        </Radio.Button>
                        <Radio.Button value="follow_up" style={{ padding: '4px 8px', fontSize: '14px' }}>
                          Follow Up
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ paddingTop: 10 }} className="justify-content-around">
                  <Col xs={24} sm={6} md={6}>
                    <Input
                      placeholder={'Email'}
                      value={filtros.email}
                      onChange={e => handleChange('email', e.target.value)}
                    />
                  </Col>
                  <Col xs={24} sm={6} md={6}>
                    <Input
                      placeholder={'Telefone'}
                      value={filtros.telefone}
                      onChange={e => {
                        handleChange('telefone', e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={6} md={6}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      locale={locale}
                      style={{ minWidth: '150px', width: '100%' }}
                      placeholder={`Data de ${
                        dataTipo === 'created_at' ? 'cadastro' : dataTipo === 'edited_at' ? 'edição' : 'atendimento'
                      }: De`}
                      value={filtros.data_cadastro_min}
                      onChange={(date, dateString) => {
                        handleChange('data_cadastro_min', date ? date.startOf('day') : null);
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={6} md={6}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      locale={locale}
                      style={{ minWidth: '150px', width: '100%' }}
                      placeholder={`Data de ${
                        dataTipo === 'created_at' ? 'cadastro' : dataTipo === 'edited_at' ? 'edição' : 'atendimento'
                      }: Até`}
                      value={filtros.data_cadastro_max}
                      onChange={(date, dateString) => {
                        handleChange('data_cadastro_max', date ? date.startOf('day') : null);
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ paddingTop: 10 }} className="justify-content-around">
                  <Col xs={24} sm={6} md={6}>
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      showSearch={true}
                      filterOption={(input, option) =>
                        (option?.children || '').toLowerCase().indexOf((input || '').toLowerCase()) >= 0
                      }
                      className="normal-select"
                      onChange={valor => {
                        handleChange('etapa', valor);
                      }}
                      placeholder={'Etapa do funil'}
                      value={filtros.etapa}
                    >
                      {etapas.some((etapa: any) => etapa.funil_personalizado)
                        ? etapas
                            .reduce((acc: any[], etapa: any) => {
                              const funil = etapa.funil_personalizado ? etapa.funil_personalizado.nome : 'Funil Padrão';
                              const existingGroup = acc.find(group => group.label === funil);

                              if (existingGroup) {
                                existingGroup.options.push(
                                  <Option key={etapa.id} value={etapa.id}>
                                    {etapa.title}
                                  </Option>,
                                );
                              } else {
                                acc.push({
                                  label: funil,
                                  options: [
                                    <Option key={etapa.id} value={etapa.id}>
                                      {etapa.title}
                                    </Option>,
                                  ],
                                });
                              }

                              return acc;
                            }, [])
                            .map(group => (
                              <OptGroup key={group.label} label={group.label}>
                                {group.options}
                              </OptGroup>
                            ))
                        : etapas.map(etapa => (
                            <Option key={etapa.id} value={etapa.id}>
                              {etapa.title}
                            </Option>
                          ))}
                    </Select>
                  </Col>
                  <Col xs={24} sm={6} md={6}>
                    <Select
                      style={{ width: '100%', minWidth: '100%' }}
                      allowClear={true}
                      className="normal-select"
                      value={filtros.transacao}
                      onChange={valor => {
                        handleChange('transacao', valor);
                      }}
                      placeholder={'Transação'}
                    >
                      {context.user?.acesso_venda && <Option value={'interesse_venda'}>Interessado em venda</Option>}
                      {context.user?.acesso_locacao && (
                        <>
                          <Option value={'interesse_locacao'}>Interessado em locação anual</Option>
                          <Option value={'interesse_locacao_temporada'}>Interessado em locação temporada</Option>
                        </>
                      )}
                    </Select>
                  </Col>
                  {(context.user?.permissões.alterar_cadastro || context.user?.permissões.lead_ver_todos) && (
                    <Col xs={24} sm={6} md={6}>
                      <Select
                        style={{ width: '100%', minWidth: '100%' }}
                        allowClear={true}
                        className="normal-select"
                        value={filtros.corretor_responsavel}
                        onChange={valor => {
                          handleChange('corretor_responsavel', valor);
                        }}
                        placeholder={'Corretor responsável'}
                        showSearch={true}
                        filterOption={(input, option) => {
                          try {
                            return (option?.children || '').toLowerCase().indexOf((input || '').toLowerCase()) >= 0;
                          } catch (error) {
                            console.log(error);
                            return false;
                          }
                        }}
                      >
                        {context.users_empresa.map((user: Corretor) => (
                          <Option key={user.db_id} value={user.db_id}>
                            {user.nome}
                          </Option>
                        ))}
                        {<Option value={'null'}>Nenhum</Option>}
                      </Select>
                    </Col>
                  )}
                  <Col xs={24} sm={6} md={6}>
                    <Select
                      mode="multiple"
                      allowClear={true}
                      value={filtros.formularios}
                      onChange={valor => {
                        handleChange('formularios', valor);
                      }}
                      style={{ width: '100%', minWidth: '100%' }}
                      placeholder="Campanhas"
                      filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
                      optionFilterProp="children"
                    >
                      {formularios &&
                        (formularios || []).map((formulario, index) => (
                          <Option key={formulario.id || index} value={formulario.id}>
                            {formulario.name}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                {BuscaExtra && (
                  <Row
                    style={{
                      marginTop: 4,
                    }}
                    gutter={[8, 8]}
                    className="justify-content-around"
                  >
                    <Col xs={24} sm={12} md={6}>
                      <SidebarSingle>
                        <StyledSelect
                          mode="multiple"
                          placeholder="Imóvel de Origem"
                          style={{ width: '100%', minWidth: '100%' }}
                          value={filtros.imovel_origem}
                          onChange={value => {
                            setFiltros({
                              ...filtros,
                              imovel_origem: value as any,
                            });
                          }}
                          onSearch={setImovelFilter}
                          loading={loadingImoveis}
                          showSearch
                          filterOption={false}
                          allowClear
                          optionLabelProp="label"
                        >
                          {opcoesImoveis.map(emp => (
                            <Option key={emp.value} value={emp.value} label={emp.label}>
                              {emp.label}
                            </Option>
                          ))}
                        </StyledSelect>
                      </SidebarSingle>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Select
                        mode="multiple"
                        value={filtros.etiquetas}
                        onChange={valor => {
                          handleChange('etiquetas', valor);
                        }}
                        style={{ width: '100%', minWidth: '100%' }}
                        placeholder="Etiquetas"
                      >
                        {(context.empresa.etiquetas || []).map(etiqueta => (
                          <Option key={etiqueta} value={etiqueta}>
                            {etiqueta}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Select
                        style={{ width: '100%', minWidth: '100%' }}
                        allowClear={true}
                        className="normal-select"
                        value={filtros.bant}
                        onChange={valor => {
                          handleChange('bant', valor);
                        }}
                        placeholder={'BANT'}
                      >
                        {context.empresa?.bant &&
                          context.empresa?.bant.map(bant => (
                            <Option key={bant} value={bant}>
                              {bant}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Select
                        mode="multiple"
                        placeholder="Construtoras"
                        style={{ width: '100%', minWidth: '100%' }}
                        value={filtros.construtoras}
                        onChange={valor => handleChange('construtoras', valor)}
                        allowClear
                      >
                        {opcoesConstrutoras.map(construtora => (
                          <Option key={construtora.db_id} value={construtora.db_id}>
                            {construtora.nome}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                )}
                <Row justify="start" gutter={[8, 8]}>
                  <Col lg={6} xs={24}>
                    <div className="custom-radio-group">
                      <Heading
                        as="h5"
                        style={{ fontSize: '13px', fontWeight: '600', lineHeight: '8px', color: '#454545' }}
                      >
                        Status:
                      </Heading>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.buscar_ganhos}
                        onChange={e => {
                          handleChange('buscar_ganhos', e.target.checked);
                        }}
                      >
                        Ganho
                      </Checkbox>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.buscar_perdidos}
                        onChange={e => {
                          handleChange('buscar_perdidos', e.target.checked);
                        }}
                      >
                        Perdido
                      </Checkbox>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.buscar_excluidos}
                        onChange={e => {
                          handleChange('buscar_excluidos', e.target.checked);
                        }}
                      >
                        Excluído
                      </Checkbox>
                    </div>
                  </Col>
                  <Col lg={6} xs={24}>
                    <div className="custom-radio-group">
                      <Heading
                        as="h5"
                        style={{ fontSize: '13px', fontWeight: '600', lineHeight: '8px', color: '#454545' }}
                      >
                        Proprietários:
                      </Heading>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.buscar_proprietarios}
                        onChange={e => {
                          handleChange('buscar_proprietarios', e.target.checked);
                        }}
                      >
                        Buscar proprietários
                      </Checkbox>
                    </div>
                  </Col>
                  <Col lg={6} xs={24}>
                    <div className="custom-radio-group">
                      <Heading
                        as="h5"
                        style={{ fontSize: '13px', fontWeight: '600', lineHeight: '8px', color: '#454545' }}
                      >
                        Origem:
                      </Heading>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.origem_site}
                        onChange={e => {
                          const value = e.target.checked ? 1 : null;
                          handleChange('origem_site', value);
                        }}
                      >
                        Site
                      </Checkbox>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.portais}
                        onChange={e => {
                          const value = e.target.checked ? 1 : null;
                          handleChange('portais', value);
                        }}
                      >
                        Portais
                      </Checkbox>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.formulario_id}
                        onChange={e => {
                          const value = e.target.checked ? 1 : null;
                          handleChange('formulario_id', value);
                        }}
                      >
                        Campanha
                      </Checkbox>
                    </div>
                  </Col>
                  <Col lg={6} xs={24} style={{ display: 'flex', gap: '8px' }}>
                    <Tooltip title="Limpar filtros">
                      <Button
                        onClick={handleLimpar}
                        style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                        icon={<ClearOutlined />}
                      ></Button>
                    </Tooltip>
                    <Button type="primary" onClick={() => setBuscar(true)} style={{ flex: 2 }}>
                      Buscar
                    </Button>
                    <Button type="default" onClick={() => setBuscaExtra(v => !v)} style={{ flex: 2 }}>
                      {BuscaExtra ? '-' : '+'} Filtros
                    </Button>
                  </Col>
                </Row>
              </TopToolBox>

              {loadingClientes ? (
                <div style={{ height: 300 }} className="spin">
                  <Spin />
                </div>
              ) : primeiraBusca ? (
                <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Heading as="h3">Filtre e liste os clientes clicando em buscar</Heading>
                </Col>
              ) : (
                <TabelaDeClientes
                  context={context}
                  onHandleFish={onHandleFish}
                  bolsao={props.bolsao}
                  CountLoading={loadingClientes}
                  permissões={context.user?.permissões}
                  FindProprietarios={filtros.buscar_proprietarios}
                  setTableReset={setTableReset}
                  TableReset={TableReset}
                  rowSelection={rowSelection}
                  SearchText={filtros.nome.length >= 2 ? filtros.nome : ''}
                  clientes={clientes}
                  setClientes={setClientes}
                  onHandleRevive={onHandleRevive}
                  BuscaAvancada={filtros}
                  showEditModal={showEditModal}
                  onHandleDelete={onHandleDelete}
                  adicionarModalCliente={adicionarModalCliente}
                  current={current}
                  pageSize={pageSize}
                  clientesTotal={clientesTotal}
                  setCurrent={setCurrent}
                  setPageSize={setPageSize}
                  setBuscar={setBuscar}
                  handleStatusChange={handleStatusChange}
                  columnOrder={columnOrder}
                  setColumnOrder={setColumnOrder}
                  openModalVisualizarCorretor={openModalVisualizarCorretor}
                />
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ListaDeClientes;
