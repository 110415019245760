import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Checkbox, message, DatePicker } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import locale from 'antd/es/date-picker/locale/pt_BR';
import FiltrarImoveis from '../../../components/filtros-smart/imoveis';
import { Imóvel } from 'smart-imob-types';

import 'moment/locale/pt-br';

interface RegistrarVisitaModalProps {
  onCancel: () => void;
  visible: boolean;
  onFinish?: (db_id, updatedImovel) => void;
  clienteId?: string;
  imovelId?: string;
  funilPersonalizado?: boolean;
  etapaFunilId?: string | null;
}

const RegistrarVisitaModal = ({
  onCancel,
  visible,
  onFinish,
  clienteId,
  imovelId,
  funilPersonalizado,
  etapaFunilId,
}: RegistrarVisitaModalProps) => {
  const context = useContext(AppContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [cliente, setCliente] = useState<{ value?: string; label?: string } | null>(null);
  const [ImovelObj, setImovelObj] = useState<Imóvel | null>(null);
  // const [etapas, setEtapas] = useState<any>([]);

  // const fetchEtapas = async () => {
  //   try {
  //     let params = new URLSearchParams({
  //       empresa_id: context.empresa.db_id,
  //     });
  //     const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-etapas?${params.toString()}`);

  //     const data = await response.json();
  //     setEtapas(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchEtapas();
  // }, []);

  const handleChangeCliente = setCliente;

  const handleOk = async values => {
    if (!values.data || !values.data.toDate) {
      return message.warning('Por favor, insira uma data para esta visita!');
    }

    let etapa_funil_ao_cadastrar_visita_id = funilPersonalizado ? etapaFunilId : context?.empresa?.etapa_funil_ao_cadastrar_visita_id;

    setLoading(true);
    try {
      const imovel_id = imovelId || values.cod_imovel.db_id;
      const body = {
        edited_by_id: context.user?.db_id,
        descrição: values.detalhes,
        cliente_id: cliente?.value || clienteId,
        agenciador_id: context.user?.db_id,
        visita_online: !!values.online,
        created_at: new Date(),
        data_visita: values.data.toDate(),
        imovel_id,
        empresa_id: context.empresa.db_id,
        etapa_funil_ao_cadastrar_visita_id,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URI}/visitas`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const visita = await response.json();

      form.resetFields();
      message.success('Visita registrada!');

      if (onFinish) {
        onFinish(imovel_id, visita);
      }
      onCancel();
    } catch (error) {
      console.error(error);
      message.error('Erro ao registrar visita!');
    } finally {
      setLoading(false);
    }
  };

  const handleChangeImovel = async val => {
    setImovelObj(val);

    if (val?.value) {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${val?.value}`);
      const imovel = await response.json();
      form.setFieldsValue({
        cod_imovel: imovel,
      });
    }
  };

  return (
    <Modal type={'primary'} title="Cadastro de Visita" visible={visible} footer={null} onCancel={onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            {
              <Form form={form} name="visita" onFinish={handleOk}>
                {!clienteId && (
                  <Form.Item initialValue="" label="Cliente">
                    <FiltrarClientes
                      style={{ height: '100%' }}
                      cliente_obj={cliente}
                      handleChangeCliente={handleChangeCliente}
                      empresa_id={context.empresa?.db_id}
                    />
                  </Form.Item>
                )}
                {!imovelId && (
                  <Form.Item name="cod_imovel" initialValue="" label="Código Imóvel:">
                    <FiltrarImoveis
                      placeholder={'Código do Imóvel'}
                      style={{ height: '100%' }}
                      imovel_obj={ImovelObj}
                      handleChangeImovel={handleChangeImovel}
                      empresa_id={context.empresa.db_id}
                    />
                  </Form.Item>
                )}
                {/* {!context?.empresa?.etapa_funil_ao_cadastrar_visita_id && (
                  <Form.Item name="etapa_funil_id" label="Etapa Funil">
                    {
                      <Select
                        style={{ width: '100%' }}
                        allowClear={true}
                        showSearch={true}
                        filterOption={(input, option) =>
                          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        className="height-fix"
                        onChange={e => SetCEtapa(e === undefined ? null : e)}
                        placeholder={'Etapa do funil'}
                        value={CEtapa || undefined}
                        size="large"
                      >
                        {etapas.map(etapa => (
                          <Option value={etapa.id}>{etapa.title}</Option>
                        ))}
                      </Select>
                    }
                  </Form.Item>
                )} */}
                <Form.Item name="detalhes" initialValue="" label="Detalhes da visita">
                  <Input placeholder="Descreva os detalhes da visita" />
                </Form.Item>
                <Form.Item name="data" initialValue="" label="Data da visita">
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    locale={locale}
                    placeholder="Selecione a data dessa visita"
                    style={{ width: '100%' }}
                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                  />
                </Form.Item>
                <Form.Item
                  name="online"
                  valuePropName={'checked'}
                  initialValue={false}
                  label="Características da visita"
                >
                  <Checkbox>Visita online</Checkbox>
                </Form.Item>
                <Button disabled={loading} htmlType="submit" size="default" type="primary" key="submit">
                  Registrar visita!
                </Button>
              </Form>
            }
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
};

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarVisitaModal;
