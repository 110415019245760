import React, { useContext } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListaDeClientes from '../../container/lista-de-clientes';
import AppContext from '../../context';

// const BolsaoDeClientes = lazy(() => import('../../container/lista-de-clientes/index_stream2'));

const BolsaoDeClientesRoutes = (props:any) => {
  const { path } = useRouteMatch();
  const context = useContext(AppContext);

  return (
    <Switch>
      <Route path={path}>
        <ListaDeClientes bolsao={true} title='Bolsão de Clientes' userId={context.user?.db_id} {...props}/>
      </Route>
    </Switch>
  );
};

export default BolsaoDeClientesRoutes;
