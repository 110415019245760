import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListaDeClientes from '../../container/lista-de-clientes';

const ListaDeClientesRoutes = (props:any) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path}>
        <ListaDeClientes title='Lista de Clientes' {...props}/>
      </Route>
    </Switch>
  );
};

export default ListaDeClientesRoutes;
