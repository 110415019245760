import React, { useContext, useEffect, useState } from 'react';
import { Row, Checkbox, message, Form, Input, Select, InputNumber, DatePicker, Col, Modal } from 'antd';
import { Cards } from '../../cards/frame/cards-frame';
import { Button } from '../../buttons/buttons';
import AppContext from '../../../context';
import { Corretor } from 'smart-imob-types';
import { ChangePasswordModal } from '../change-password';
import { UserDeleteModal } from '../delete';
import { WhatsAppOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { BasicFormWrapper } from '../../../container/styled';
import dayjs from 'dayjs';

interface EditarPerfilProps {
  usuario: Corretor;
  meuPerfil: boolean;
  isAdmin: boolean;
}

export const EditarCorretor = ({ usuario, meuPerfil, isAdmin }: EditarPerfilProps) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [openModalRemover, setOpenModalRemover] = useState(false);
  const [openModalAlterarSenha, setOpenModalAlterarSenha] = useState(false);

  const [isVideoValid, setIsVideoValid] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string>('');

  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    setLoading(true);
    let acesso_venda = false;
    let acesso_locacao = false;

    if (values.acesso === 'venda') {
      acesso_venda = true;
      acesso_locacao = false;
    } else if (values.acesso === 'locacao') {
      acesso_venda = false;
      acesso_locacao = true;
    } else if (values.acesso === 'ambos') {
      acesso_venda = true;
      acesso_locacao = true;
    }

    delete values.acesso;

    const payload = {
      ...values,
      acesso_venda,
      acesso_locacao,
    };

    try {
      if (values.email !== usuario.email && usuario.email) {
        payload.email = values.email;
      }
      if (values.telefone) {
        payload.telefone = values.telefone.replace(/\./g, '').replace(/\+/g, '');
      }
      if (values.nome !== usuario.nome && usuario.nome) {
        payload.nome = values.nome;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/${usuario.db_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Falha ao editar o usuário!');
      }

      message.success('Dados editados com sucesso!');
    } catch (error) {
      console.log(error);
      message.error('Algo deu errado ao editar os dados!');
    } finally {
      setLoading(false);
    }
  };

  const sendTestWhatsAppMessage = async () => {
    setLoading(true);
    try {
      const gerarDinamico = () => {
        const letras = Math.random()
          .toString(36)
          .substring(2, 7)
          .toUpperCase();
        const numeros = Math.floor(Math.random() * 100000)
          .toString()
          .padStart(5, '0');
        return letras + numeros;
      };

      await fetch(`${process.env.REACT_APP_API_URI}/notificacoes/teste-whatsapp`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usuario: context.user,
          cliente: {
            db_id: gerarDinamico(),
            titulo: `SMTX Teste - ${gerarDinamico()}`,
            created_at: new Date(),
            telefone: gerarDinamico(),
            DDD: gerarDinamico(),
            imovel_origem: {
              titulo: `Imóvel de Teste - ${gerarDinamico()}`,
            },
            corretor_responsavel: {
              db_id: gerarDinamico(),
              nome: context.empresa.nome,
            },
            formulario: {
              name: `Formulário - ${gerarDinamico()}`,
            },
          },
        }),
      });
      message.success('Mensagem de teste enviada com sucesso!');
    } catch (error) {
      message.error('Erro ao enviar mensagem de teste.');
    }
    setLoading(false);
  };

  useEffect(() => {
    setVideoUrl(usuario.video_site || '');
  }, [usuario.video_site]);

  const validateVideoUrl = (url: string) => {
    if (!url) {
      setIsVideoValid(false);
      return;
    }

    const videoExtensions = ['.mp4', '.webm', '.mov', '.avi'];
    const isFileFormat = videoExtensions.some(ext => url.toLowerCase().includes(ext));
    const isYouTubeOrVimeo = url.toLowerCase().includes('youtube.com') || url.toLowerCase().includes('vimeo.com');

    if (isFileFormat || isYouTubeOrVimeo) {
      setIsVideoValid(true);
    } else {
      setIsVideoValid(false);
    }
  };

  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value.trim();
    setVideoUrl(url);
    validateVideoUrl(url);
  };

  const renderVideoContent = () => {
    const isYouTube = videoUrl.toLowerCase().includes('youtube.com') || videoUrl.toLowerCase().includes('youtu.be');
    const isVimeo = videoUrl.toLowerCase().includes('vimeo.com');

    if (isYouTube || isVimeo) {
      const getYouTubeEmbedUrl = (originalUrl: string) => {
        try {
          if (originalUrl.includes('watch?v=')) {
            const urlObj = new URL(originalUrl);
            const videoId = urlObj.searchParams.get('v');
            return `https://www.youtube.com/embed/${videoId}`;
          }

          if (originalUrl.includes('youtu.be/')) {
            const parts = originalUrl.split('/');
            const videoId = parts[parts.length - 1];
            return `https://www.youtube.com/embed/${videoId}`;
          }

          return originalUrl;
        } catch (error) {
          return originalUrl;
        }
      };
      return (
        <iframe
          title="Vídeo de apresentação"
          width="100%"
          height="400px"
          src={getYouTubeEmbedUrl(videoUrl)}
          frameBorder="0"
          allowFullScreen
        />
      );
    }

    return (
      <video controls style={{ width: '100%', height: '400px' }}>
        <source src={videoUrl} />
        Seu navegador não suporta a reprodução de vídeo.
      </video>
    );
  };

  return (
    <Cards title="Edite suas informações">
      <UserDeleteModal
        open={openModalRemover}
        setOpen={setOpenModalRemover}
        userId={usuario.db_id}
        empresaId={context.empresa?.db_id}
      />
      <ChangePasswordModal open={openModalAlterarSenha} setOpen={setOpenModalAlterarSenha} userId={usuario.db_id} />

      <Modal
        title="Pré-visualização do Vídeo"
        visible={showVideoModal}
        footer={null}
        onCancel={() => setShowVideoModal(false)}
        width={800}
      >
        {renderVideoContent()}
      </Modal>

      <Row gutter={[16, 16]} style={{ justifyContent: 'center' }}>
        {usuario ? (
          <BasicFormWrapper>
            <Form form={form} name="register" onFinish={handleSubmit} layout="vertical" style={{ maxWidth: 900 }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Usuário (Apelido)" name="user_apelido" initialValue={usuario.user_apelido || ''}>
                    <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="Usuário" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item required label="Nome" name="nome" initialValue={usuario.nome || ''}>
                    <Input
                      disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                      placeholder="Nome completo"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item required name="email" label="Email" initialValue={usuario.email || ''}>
                    <Input
                      disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                      placeholder="seu@email.com"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="instagram" label="Instagram" initialValue={usuario.instagram || ''}>
                    <Input
                      disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                      placeholder="Instagram"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Cargo" name="cargo" initialValue={usuario.cargo || ''}>
                    <Input placeholder="Cargo do funcionário" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    required
                    label="Setor"
                    name="acesso"
                    initialValue={
                      usuario.acesso_venda && usuario.acesso_locacao
                        ? 'ambos'
                        : usuario.acesso_locacao
                        ? 'locacao'
                        : 'venda'
                    }
                  >
                    <Select
                      size="large"
                      placeholder="Selecione o setor que o usuário terá acesso"
                      style={{ width: '100%', marginBottom: 20 }}
                      dropdownMatchSelectWidth={200}
                    >
                      <Select.Option value="venda">Venda</Select.Option>
                      <Select.Option value="locacao">Locação</Select.Option>
                      <Select.Option value="ambos">Ambos</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item required label="Telefone" name="telefone" initialValue={usuario.telefone || ''}>
                    <Input disabled={!context.user?.permissões.alterar_cadastro} placeholder="Telefone" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Link WhatsApp" name="whatsapp_link" initialValue={usuario.whatsapp_link || ''}>
                    <Input placeholder="Link WhatsApp no site caso preenchido" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="CRECI" label="CRECI" initialValue={usuario.CRECI || ''}>
                    <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="CRECI" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="CPF" label="CPF" initialValue={usuario.CPF || ''}>
                    <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="CPF" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue={usuario.data_de_nascimento ? dayjs(usuario.data_de_nascimento) : null}
                    name="data_de_nascimento"
                    label="Data de nascimento"
                  >
                    <DatePicker size="large" allowClear placeholder="Data de nascimento" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    initialValue={usuario.data_de_contratacao ? dayjs(usuario.data_de_contratacao) : null}
                    name="data_de_contratacao"
                    label="Data de contratação"
                    style={{ width: '100%' }}
                  >
                    <DatePicker size="large" allowClear placeholder="Data de contratação" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="anos_de_experiencia"
                    label="Anos de experiência"
                    initialValue={usuario.anos_de_experiencia || null}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder="Anos de experiência" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="video_site"
                    label="Vídeo de apresentação no site"
                    initialValue={usuario.video_site || ''}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Insira a URL do vídeo (Formatos: .mp4, .webm, .mov, .avi, ou link do YouTube/Vimeo)"
                        onChange={handleVideoChange}
                        value={videoUrl}
                      />
                      {isVideoValid && (
                        <PlayCircleOutlined
                          style={{ fontSize: 24, color: '#1890ff', cursor: 'pointer', marginLeft: 8 }}
                          onClick={() => setShowVideoModal(true)}
                        />
                      )}
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="bio" label="BIO" initialValue={usuario.bio || ''}>
                    <Input.TextArea
                      disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                      placeholder="Bio do Funcionário"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="receber_notificacao"
                    valuePropName="checked"
                    initialValue={!!usuario.receber_notificacao}
                  >
                    <Checkbox disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}>
                      Receber notificações no aplicativo e no sistema web!
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="aparecer_site" valuePropName="checked" initialValue={usuario.aparecer_site}>
                    <Checkbox disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}>
                      Exibir no site
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="parceiro" valuePropName="checked" initialValue={usuario.parceiro}>
                    <Checkbox disabled={!context.user?.permissões.alterar_cadastro}>Corretor Parceiro</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="mostrar_todos_os_imoveis_site"
                    valuePropName={'checked'}
                    initialValue={usuario.mostrar_todos_os_imoveis_site}
                  >
                    <Checkbox disabled={!context.user?.permissões.alterar_cadastro}>
                      Mostrar todos os imóveis da empresa no perfil do site?
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end" gutter={[16, 16]}>
                <Col>
                  <Button
                    disabled={(!meuPerfil && !isAdmin) || loading}
                    onClick={form.submit}
                    className="btn-create"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    Atualizar dados
                  </Button>
                </Col>
                {isAdmin && (
                  <>
                    <Col>
                      <Button
                        danger
                        disabled={loading}
                        style={{ marginLeft: 8 }}
                        className="btn-create"
                        type="warning"
                        size="large"
                        onClick={() => setOpenModalAlterarSenha(true)}
                      >
                        Alterar senha
                      </Button>
                    </Col>
                    {!meuPerfil && !usuario.permissões?.alterar_cadastro && (
                      <Col>
                        <Button
                          danger
                          disabled={loading}
                          style={{ marginLeft: 8 }}
                          className="btn-create"
                          type="danger"
                          size="large"
                          onClick={() => setOpenModalRemover(true)}
                        >
                          Remover usuário
                        </Button>
                      </Col>
                    )}
                    <Col>
                      <Button
                        disabled={loading}
                        style={{ marginLeft: 8 }}
                        className="btn-create"
                        type="success"
                        icon={<WhatsAppOutlined />}
                        size="large"
                        onClick={sendTestWhatsAppMessage}
                      >
                        Testar WhatsApp
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </BasicFormWrapper>
        ) : null}
      </Row>
    </Cards>
  );
};
