import { Checkbox, Form, Input, Select, message } from 'antd';
import React, { useState } from 'react';
import { Modal } from '../../modals/antd-modals';
import { Button } from '../../buttons/buttons';
import firebase from '../../../firebase';

export const UserCreateModal = ({ visible, onCancel, isAdmin, empresaId }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async values => {
    setLoading(true);
    try {
      if (!isAdmin) {
        return message.warn('Apenas administradores podem adicionar novos usuários');
      }
      const { nome, email, telefone, senha, CRECI, acesso, parceiro } = values;

      if (!nome || !email || !telefone || !acesso) {
        setLoading(false);
        return message.warn('Preencha todos os campos');
      }

      let acesso_venda = false;
      let acesso_locacao = false;

      if (acesso === 'venda') {
        acesso_venda = true;
        acesso_locacao = false;
      } else if (acesso === 'locacao') {
        acesso_venda = false;
        acesso_locacao = true;
      } else if (acesso === 'ambos') {
        acesso_venda = true;
        acesso_locacao = true;
      }

      const token = await firebase.auth().currentUser?.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome,
          email,
          telefone,
          CRECI: CRECI || '',
          empresa_id: empresaId,
          senha,
          criador_token: token,
          acesso_venda,
          acesso_locacao,
          parceiro,
        }),
      });
      if (!response.ok) {
        setLoading(false);
        const data = await response.json();
        return message.error(data.message || 'Algo deu errado ao tentar vincular esse usuário!');
      }

      message.success('Usuário vinculado com sucesso!');
      onCancel();
    } catch (error) {
      message.error('Algo deu errado ao tentar vincular esse usuário');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal type={'primary'} title="Criar novo usuário" visible={visible} footer={null} onCancel={onCancel}>
      <div className="project-modal">
        <Form form={form} layout={'vertical'} name="contact" onFinish={handleSubmit}>
          <Form.Item
            required={true}
            initialValue=""
            name="nome"
            label="Nome"
            rules={[{ required: true, message: 'Por favor, insira o nome do usuário!' }]}
          >
            <Input placeholder="Nome" />
          </Form.Item>
          <Form.Item
            required={true}
            initialValue=""
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Por favor, insira o email do usuário!' },
              { type: 'email', message: 'Por favor, insira um email válido!' },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            required={true}
            initialValue=""
            name="senha"
            label="Senha"
            rules={[
              { required: true, message: 'Por favor, insira a senha!' },
              { min: 6, message: 'A senha deve ter no mínimo 6 caracteres!' },
            ]}
          >
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Form.Item
            required={true}
            initialValue=""
            name="telefone"
            label="Telefone"
            rules={[{ required: true, message: 'Por favor, insira o telefone do usuário!' }]}
          >
            <Input placeholder="Telefone" />
          </Form.Item>
          <Form.Item
            required={true}
            name="acesso"
            label="Acesso"
            rules={[{ required: true, message: 'Por favor, selecione o tipo de acesso!' }]}
          >
            <Select
              size="large"
              placeholder="Selecione o setor que o usuário terá acesso"
              style={{ width: '100%', marginBottom: 20 }}
              dropdownMatchSelectWidth={200}
            >
              <Select.Option value="venda">Venda</Select.Option>
              <Select.Option value="locacao">Locação</Select.Option>
              <Select.Option value="ambos">Ambos</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item initialValue="" name="CRECI" label="CRECI">
            <Input placeholder="CRECI" />
          </Form.Item>
          <Form.Item name="parceiro" valuePropName={'checked'}>
            <Checkbox>Corretor Parceiro</Checkbox>
          </Form.Item>
          <Button onClick={form.submit} disabled={loading} htmlType="submit" size="default" type="primary" key="submit">
            Criar usuário!
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
