import { Suspense, useContext, useState } from 'react';
import AppContext from '../../../context';
import { Corretor } from 'smart-imob-types';
import { PageHeader } from '../../../components/page-headers/page-headers';
import React from 'react';
import { Main } from '../../styled';
import { Col, Row, Skeleton } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { SettingWrapper, UserBio, UserCards } from '../../../components/users';
import { Switch } from 'react-router-dom';
import { EditarCorretor } from '../../../components/users/view/editar';
import Permissoes from '../../../components/users/view/permissoes';
import Imoveis from '../../../components/users/view/imoveis';
import { Clientes } from '../../../components/users/view/clientes';
import { Historico } from '../../../components/users/view/historico';
import { CorretorCoverSection } from '../../profile/overview/CorretorCoverSection';

interface UsersViewProps {
  usuario: Corretor | null;
  isModal?: boolean;
  navLinkProps?: string;
}

export const UsersView = ({ usuario, isModal = false, navLinkProps }: UsersViewProps) => {
  const context = useContext(AppContext);
  const [navLink, setNavLink] = useState(navLinkProps || 'editar');

  if (!usuario) {
    return <></>;
  }

  const meuPerfil = context.user?.db_id === usuario.db_id;
  const isAdmin = context.user?.permissões.alterar_cadastro || false;

  const onChangeComponent = id => {
    setNavLink(id);
  };

  return (
    <>
      {!isModal && (
        <PageHeader back ghost title={meuPerfil ? 'Meu perfil' : `Perfil de ${usuario.nome || usuario.email}`} />
      )}
      <Main>
        <Row style={isModal ? { padding: 20 } : undefined} gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar active paragraph={{ rows: 3 }} />
                </Cards>
              }
            >
              <UserCards showActions={false} meuPerfil={meuPerfil} user={usuario} />
            </Suspense>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </Cards>
              }
            >
              <UserBio usuario={usuario} meuPerfil={meuPerfil} isAdmin={isAdmin} />
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <div className="coverWrapper">
                  <CorretorCoverSection
                    canAdd={meuPerfil || isAdmin}
                    banner={usuario.banner_site}
                    usuarioId={usuario.db_id}
                    empresaId={context.empresa?.db_id}
                  />
                  <nav className="profileTab-menu">
                    <ul>
                      <li>
                        <a className={navLink === 'editar' ? 'active' : ''} onClick={() => onChangeComponent('editar')}>
                          Editar Dados
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'permissoes' ? 'active' : ''}
                          onClick={() => onChangeComponent('permissoes')}
                        >
                          Permissões
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'imoveis' ? 'active' : ''}
                          onClick={() => onChangeComponent('imoveis')}
                        >
                          Imóveis Agenciados
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'clientes' ? 'active' : ''}
                          onClick={() => onChangeComponent('clientes')}
                        >
                          Clientes Delegados
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'historico' ? 'active' : ''}
                          onClick={() => onChangeComponent('historico')}
                        >
                          Histórico
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Suspense>
              <Switch>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active paragraph={{ rows: 10 }} />
                    </Cards>
                  }
                >
                  {navLink === 'editar' && <EditarCorretor meuPerfil={meuPerfil} isAdmin={isAdmin} usuario={usuario} />}
                  {navLink === 'permissoes' && (
                    <Permissoes
                      meuPerfil={meuPerfil}
                      isAdmin={isAdmin}
                      permissao_id={usuario?.permissões?.id || usuario?.permissao_id}
                      cargo={usuario.cargo}
                    />
                  )}
                  {navLink === 'imoveis' && <Imoveis usuario_id={usuario.db_id} empresa_id={usuario.empresa_id} />}
                  {navLink === 'clientes' && <Clientes usuario_id={usuario.db_id} empresa_id={usuario.empresa_id} />}
                  {navLink === 'historico' && <Historico usuario_id={usuario.db_id} empresa_id={usuario.empresa_id} />}
                </Suspense>
              </Switch>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
};
