import React, { useState, useRef, useEffect } from 'react';
import { message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import firebase from 'firebase/app';

interface CoverSectionProps {
  banner?: string | undefined;
  usuarioId?: string | undefined;
  empresaId: string;
  canAdd?: boolean;
}

const getBase64 = (img: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.onerror = (e) => reject(e.target?.result);
    reader.readAsDataURL(img);
  });

export const CorretorCoverSection = ({
  banner,
  usuarioId,
  empresaId,
  canAdd = false,
}: CoverSectionProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [bannerUrl, setBannerUrl] = useState<string | undefined>(banner);
  const [hovered, setHovered] = useState<boolean>(false);

  const FileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setBannerUrl(banner);
  }, [banner]);

  const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);

    try {
      const files = e.target.files;
      if (!files || files.length === 0) {
        message.info('Nenhum arquivo selecionado!');
        return;
      }

      if (files.length > 1) {
        message.info('Envie apenas um banner por vez!');
        return;
      }

      const file = files[0];
      if (file) {
        // Converte a imagem para Base64
        const foto_base64: any = await getBase64(file);

        // Faz upload para o Firebase
        const main_ref = firebase
          .storage()
          .ref(`empresas/${empresaId}/`)
          .child(`banner_usuario_${usuarioId}_${new Date().getTime()}`);
        const novo_banner_res = await main_ref.putString(foto_base64, 'data_url', {
          contentType: 'image/jpg',
        });
        const novo_banner_url = await novo_banner_res.ref.getDownloadURL();

        let url = usuarioId ? `corretores/${usuarioId}` : `empresas/${empresaId}`;
        let body = usuarioId ? {
          banner_site: novo_banner_url,
        } : {
          banner_site: novo_banner_url,
        }

        await fetch(`${process.env.REACT_APP_API_URI}/${url}`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        setBannerUrl(novo_banner_url);
        message.success('Banner atualizado com sucesso!');
      }
    } catch (error) {
      console.log(error);
      message.error('Erro ao atualizar o banner!');
    } finally {
      setLoading(false);
    }
  };

  // Handler para remover o banner (enviando null para o backend)
  const handleRemoveBanner = async () => {
    setLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URI}/corretores/${usuarioId}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          banner_site: null,
        }),
      });
      setBannerUrl(undefined);
      message.success('Banner removido com sucesso!');
    } catch (error) {
      console.log(error);
      message.error('Erro ao remover o banner!');
    } finally {
      setLoading(false);
    }
  };

  const handleUploadClick = () => {
    if (!loading) {
      FileRef.current?.click();
    }
  };

  return (
    <div
      className="cover-image-wrapper"
      style={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 10,
        width: '100%',
        height: 210,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Imagem de fundo */}
      <div
        className="cover-image"
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: bannerUrl ? `url('${bannerUrl}')` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 10,
          transition: 'all 0.3s ease-in-out',
          filter: hovered ? 'brightness(0.8)' : 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: bannerUrl ? undefined : '#f0f0f0',
        }}
      >
        {/* Caso não tenha banner, exibe um ícone ou texto indicando vazio */}
        {!bannerUrl && (
          <span style={{ fontSize: 14, color: '#999' }}>Nenhum banner configurado</span>
        )}
      </div>

      {canAdd && (
        <>
          {/* Botão "Escolher arquivo" invisível */}
          <input
            type="file"
            onChange={onFileUpload}
            ref={FileRef}
            accept="image/x-png,image/gif,image/jpeg"
            style={{ display: 'none' }}
          />

          {/* Área de ações (exibe ao passar o mouse) */}
          {hovered && (
            <div
              className="cover-image-actions"
              style={{
                position: 'absolute',
                bottom: 10,
                left: 10,
                zIndex: 2,
                display: 'flex',
                gap: 8,
              }}
            >
              {/* Remover Banner */}
              {bannerUrl && (
                <div
                  style={{
                    cursor: loading ? 'not-allowed' : 'pointer',
                    opacity: loading ? 0.5 : 1,
                    backgroundColor: '#fff',
                    borderRadius: 4,
                    padding: '4px 8px',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
                  }}
                  onClick={() => !loading && handleRemoveBanner()}
                >
                  <FeatherIcon icon="trash-2" size={16} style={{ marginRight: 4 }} />
                  <span style={{ fontSize: 12, fontWeight: 500 }}>Remover Banner</span>
                </div>
              )}

              {/* Adicionar / Alterar Banner */}
              <div
                style={{
                  cursor: loading ? 'not-allowed' : 'pointer',
                  opacity: loading ? 0.5 : 1,
                  backgroundColor: '#1890ff',
                  color: '#fff',
                  borderRadius: 4,
                  padding: '4px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
                }}
                onClick={handleUploadClick}
              >
                <FeatherIcon icon={loading ? 'loader' : 'camera'} size={16} style={{ marginRight: 4 }} />
                <span style={{ fontSize: 12, fontWeight: 500 }}>
                  {bannerUrl ? 'Alterar Banner' : 'Adicionar Banner'}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};